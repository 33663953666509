<template>
  <div>
    <h4>Bulk Plan</h4>

    <div class="row">
      <div class="col">
        <div class="background p-3">
          <div class="row align-items-center">
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Name" v-model="name" />
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Description" v-model="description" />
            </div>
            <div class="col-2">
              <button class="vave-btn btn-green" @click="updatePlan()">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Breaks -->

    <div class="row">
      <div class="col">
        <div class="background p-3 mt-4">
          <div class="row">
            <div class="col">
              <h6>Breaks (click to edit)</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Discount Percentage</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="planBreak,key in breaks"
                      :key="key"
                      :class="{
                      'background-tmp': planBreak.is_new,
                      'background-deleted': planBreak.is_deleted,
                      'invalid-row': !isBreakValid(key),
                      }"
                      @click="editBreak(key)"
                    >
                      <td v-if="planBreak.editable" class="fixed-width-td">
                        <input class="editable-input" :class="{'invalid-number-input': !isBreakValid(key)}" type="number" min="0" v-model="planBreak.quantity_from" />
                      </td>
                      <td v-else class="fixed-width-td">{{planBreak.quantity_from}}</td>
                      <td v-if="planBreak.editable" class="fixed-width-td">
                        <input class="editable-input" :class="{'invalid-number-input': !isBreakValid(key)}" type="number" min="1" v-model="planBreak.quantity_to" />
                      </td>
                      <td v-else class="fixed-width-td">{{planBreak.quantity_to ? planBreak.quantity_to: '∞' }}</td>

                      <td v-if="planBreak.editable" class="fixed-width-td">
                        <input class="editable-input" :class="{'invalid-number-input': !isBreakValid(key)}" type="number" min="0" max="100" v-model="planBreak.discount_percentage" />%
                      </td>
                      <td v-else class="fixed-width-td">{{planBreak.discount_percentage}}%</td>
                      <td class="d-flex">
                        <button class="vave-btn btn-small me-1" @click="editBreak(key)">Edit</button>
                        <button class="vave-btn btn-small btn-outline-red" @click="deleteBreak(key)" v-if="!planBreak.is_deleted">Delete</button>
                        <button class="vave-btn btn-small" @click="deleteBreak(key)" v-else>Undo</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" v-if="canAddBreaks">
            <div class="col-3">
              <input type="number" class="form-control" placeholder="From" v-model="breakFrom" :class="{ 'is-invalid': fromInputError }" />
              <div class="invalid-feedback" v-html="fromInputError"></div>
            </div>
            <div class="col-3">
              <input type="number" class="form-control" placeholder="To (empty field corresponds to infinity)" v-model="breakTo" :class="{ 'is-invalid': toInputError }" />
              <div class="invalid-feedback" v-html="toInputError"></div>
            </div>

            <div class="col-3">
              <div class="input-group">
                <input type="number" min="0" max="100" class="form-control" placeholder="Discount Percentage" v-model="breakDiscount" aria-describedby="basic-addon1" :class="{ 'is-invalid': discountInputError }" />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon1">%</span>
                </div>
                <div class="invalid-feedback" v-html="discountInputError"></div>
              </div>
            </div>

            <div class="col-3">
              <button class="vave-btn btn-green" @click="addBreak()">Add Break</button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <button class="vave-btn btn-green" @click="saveBreaks()" :disabled="!canSave">Save Breaks</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="background mt-4 p-3">
          <div class="row">
            <div class="col">
              <h6>Products</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row align-items-center">
                <div class="col-6">
                  <div class="my-2">
                    <products-input v-if="productsReady" v-model="products" type="name" key="products" @ready="enableSync()" />
                  </div>
                </div>
                <div class="col-2">
                  <button class="vave-btn btn-green" @click="pushProducts()" :disabled="!productsInputReady">Save Products</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="entries-table">
                <table class="table">
                  <thead>
                    <th>id</th>
                    <th>name</th>
                    <th>sku</th>
                  </thead>
                  <tbody>
                    <tr v-for="product,k in products" :key="k" :class="{'synched': !productsSynched.includes(product.id)}">
                      <td>{{product.id}}</td>
                      <td>{{product.name}}</td>
                      <td>{{product.sku}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="background mt-4 p-3">
          <div class="row">
            <div class="col">
              <h6>Categories</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row align-items-center">
                <div class="col-6">
                  <div class="my-2">
                    <categories-input v-if="categoriesReady" v-model="categories" type="name" key="categories" @ready="enableSync()" />
                  </div>
                </div>
                <div class="col-2">
                  <button class="vave-btn btn-green" @click="pushCategories()" :disabled="!categoriesInputReady">Save Categories</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="entries-table">
                <table class="table">
                  <thead>
                    <th>id</th>
                    <th>name</th>
                    <th>sku</th>
                  </thead>
                  <tbody>
                    <tr v-for="category,k in categories" :key="k" :class="{'synched': !categoriesSynched.includes(category.id)}">
                      <td>{{category.id}}</td>
                      <td>{{category.name}}</td>
                      <td>{{category.sku}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WatchObject from "./WatchObject.vue";
import axios from "../http.js";
import ProductsInput from "./widgets/helpers/Products";
import CategoriesInput from "./widgets/helpers/CategoriesMultiselect";

export default {
  extends: WatchObject,
  data() {
    return {
      categoriesReady: false,
      productsReady: false,
      name: "",
      description: "",
      categoriesInputReady: false,
      productsInputReady: false,
      published: true,
      plan: [],
      categories: [],
      products: [],
      categoriesSynched: [],
      productsSynched: [],
      bulksList: [],
      breaks: [],
      breakFrom: "",
      breakTo: "",
      breakDiscount: "",
      fromInputError: null,
      toInputError: null,
      discountInputError: null,
    };
  },
  components: { ProductsInput, CategoriesInput },
  mounted() {
    this.getBreakPlan();
  },
  watch: {
    breaks: {
      deep: true,
      handler(val) {
        this.notifyUnsavedChanges(this.mapQuantitiesToInts(val));
      },
    },
  },
  computed: {
    canSave() {
      // check no break is invalid
      var areValid = true;
      this.breaks.forEach((breakItem, key) => {
        if (this.isBreakValid(key) !== true) {
          areValid = false;
        }
      });
      return areValid == true;
    },
    canAddBreaks() {
      // return false if we have an empty "quantity_to" between the breaks
      var haveInfinity = false;
      this.breaks.forEach(function (breakItem) {
        if (!breakItem.quantity_to) {
          haveInfinity = true;
        }
      });
      return !haveInfinity;
    },
  },
  methods: {
    mapQuantitiesToInts(val) {
      var b = [];
      val.forEach((breakItem) => {
        b.push({
          quantity_from: parseInt(breakItem.quantity_from),
          quantity_to: parseInt(breakItem.quantity_to),
          discount_percentage: parseInt(breakItem.discount_percentage),
        });
      });
      return b;
    },
    addBreak() {
      this.fromInputError = null;
      this.toInputError = null;
      this.discountInputError = null;

      if (this.breakFrom == "" || this.breakDiscount == "") {
        this.fromInputError = "All fields are required";
        this.discountInputError = "All fields are required";
        return;
      }
      // validate input
      let from = parseInt(this.breakFrom);
      let to = parseInt(this.breakTo);
      let discount = parseInt(this.breakDiscount);

      if (this.breaks.length > 0) {
        let lastBreak = this.breaks[this.breaks.length - 1];
        if (lastBreak && from <= parseInt(lastBreak.quantity_to)) {
          this.fromInputError = "FROM must be higher than previous TO";
          this.toInputError = "FROM must be higher than previous TO";
          return;
        }
      }

      if (to && from > to) {
        this.fromInputError = "FROM must be lower than TO";
        this.toInputError = "FROM must be lower than TO";
        return;
      }

      if (discount > 100) {
        this.discountInputError = "Discount cannot be higher than 100";
        return;
      }

      this.breaks.push({
        quantity_from: from,
        quantity_to: to,
        discount_percentage: this.breakDiscount,
        is_new: true,
      });
      if (!to) {
        return;
      }
      let interval = to - from;
      this.breakFrom = to + 1;
      this.breakTo = this.breakFrom + interval;
      this.breakDiscount = Math.min(100, discount + 5);
    },
    isBreakValid(key) {
      let current = this.breaks[key];
      if (parseInt(current.quantity_from) > parseInt(current.quantity_to)) {
        return false;
      }
      let prev = this.breaks[key - 1];
      if (!prev) {
        return true;
      }
      let next = this.breaks[key + 1];
      if (!current.quantity_to && next) {
        // Cannot have other rows after infinity
        return false;
      }
      if (parseInt(current.quantity_from) <= parseInt(prev.quantity_to)) {
        return false;
      }
      return true;
    },
    deleteBreak(key) {
      this.breaks[key].is_deleted = !this.breaks[key].is_deleted;
      this.breaks = [...this.breaks];
    },
    editBreak(key) {
      // Make all the other breaks not editable
      this.breaks.forEach(function (breakItem) {
        breakItem.editable = false;
      });
      this.breaks[key].editable = true;
      this.breaks = [...this.breaks];
    },
    saveBreaks() {
      // remove the deleted breaks
      this.breaks = this.breaks.filter(function (breakItem) {
        return !breakItem.is_deleted;
      });

      axios
        .post(
          "/api/admin/bulk-price-plans/" + this.$route.params.id + "/breaks",
          {
            breaks: this.mapQuantitiesToInts(this.breaks),
          }
        )
        .then(() => {
          this.$toast.success("Breaks saved correctly");
          this.getBreakPlan();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    getBreakPlan() {
      axios
        .get("/api/admin/bulk-price-plans/" + this.$route.params.id)
        .then((response) => {
          if (response.data.data) {
            let res = response.data.data;
            this.plan = response.data.data;
            this.name = res.name;
            this.description = res.description;
            var b = [];
            res.breaks.forEach((breakItem) => {
              b.push({
                quantity_from: parseInt(breakItem.quantity_from),
                quantity_to: parseInt(breakItem.quantity_to),
                discount_percentage: parseInt(breakItem.discount_percentage),
              });
            });
            this.breaks = b;
            this.setObjectHash(b);
          }
        });

      axios
        .get(
          "/api/admin/bulk-price-plans/" + this.$route.params.id + "/categories"
        )
        .then((response) => {
          if (response.data.data) {
            let categories = response.data.data;
            var p = [];
            var p2 = [];
            categories.forEach((category) => {
              p.push({
                id: category.id,
                name: category.name,
              });
              p2.push(category.id);
            });
            this.categories = p;
            this.categoriesSynched = p2;
            this.categoriesReady = true;
          }
        });

      axios
        .get(
          "/api/admin/bulk-price-plans/" + this.$route.params.id + "/products"
        )
        .then((response) => {
          if (response.data.data) {
            let products = response.data.data;
            var p = [];
            var p2 = [];
            products.forEach((product) => {
              p.push({
                id: product.id,
                name: product.name,
                description: product.description,
                sku: product.sku,
              });
              p2.push(product.id);
            });
            this.products = p;
            this.productsSynched = p2;
            this.productsReady = true;
          }
        });
    },
    enableSync() {
      this.productsInputReady = true;
      this.categoriesInputReady = true;
    },
    updatePlan() {
      if (
        !confirm(
          'do you want to update the plan with name "' +
            this.name +
            '" and description "' +
            this.description +
            '"?'
        )
      ) {
        return;
      }
      axios
        .put("/api/admin/bulk-price-plans/" + this.$route.params.id, {
          name: this.name,
          description: this.description,
          published: this.published,
        })
        .then(
          () => {
            this.getBreakPlan();
          },
          (e) => {
            console.log(e.response.data);
            alert(e.response.data.message);
          }
        );
    },
    pushProducts() {
      axios
        .put(
          "/api/admin/bulk-price-plans/" + this.$route.params.id + "/products",
          {
            products: this.products,
          }
        )
        .then(
          () => {
            this.$toast.success("Products synchronised");
            this.getBreakPlan();
          },
          (e) => {
            console.log(e.response.data);
            this.$toast.error(e.response.data.message);
          }
        );
    },
    pushCategories() {
      axios
        .put(
          "/api/admin/bulk-price-plans/" +
            this.$route.params.id +
            "/categories",
          {
            categories: this.categories,
          }
        )
        .then(
          () => {
            this.$toast.success("Categories synchronised");
            this.getBreakPlan();
          },
          (e) => {
            console.log(e.response.data);
            this.$toast.error(e.response.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
}
.background-tmp {
  background: hsl(42, 100%, 71%);
}
.background-deleted {
  background: hsl(0, 95%, 65%);
}
.entries-table {
  tbody tr {
    &:hover {
      background: #fafafa;
    }
  }
}
.create {
  margin: 20px 0px;
  padding-bottom: 20px;
}
.synched {
  background: hsl(42, 100%, 71%);
}
.editable-input {
  border: none;
  border-bottom: 1px dotted black;
  width: 60px;
}
.invalid-number-input {
  color: red;
  border-bottom: 1px dotted red;
}
.invalid-row {
  color: red;
}
.fixed-width-td {
  width: 300px;
}
</style>